import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { RotatingLines } from "react-loader-spinner";
import "./SharedLayout.css";

export const SharedLayout = () => {
  return (
    <div>
      <Header />
      <Suspense
        fallback={
          <div className="loader">
            <RotatingLines
              strokeColor="#eab70e"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        }
      >
        <Outlet />
      </Suspense>
      <Footer />
    </div>
  );
};
